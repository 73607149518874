/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


ion-header{
    &:after{ 
        display: none;
        overflow: hidden;
    }
    ion-toolbar{
        ion-button, .button-native, .back-button-has-icon-only{
            --box-shadow: 0;
            --background: inherit;
            border-radius: 50%;
            margin: 0;
            box-sizing: border-box;
        }
        ion-title{
            padding-left: 50px;
            padding-right: 50px;
        }
    }
}
ion-tab-bar{
    --background: var(--ion-color-secondary);
    height: 60px;
    ion-tab-button{
        --color: #fff;
        --color-selected: #F7D046;
    }
}
ion-content{
    --padding-top: 35px;
    --padding-end: 25px;
    --padding-bottom: 50px;
    --padding-start: 25px;
    &.noPadding{
        --padding-top: 0;
        --padding-end: 0;
        --padding-bottom: 0;
        --padding-start: 0;
    }
}

ion-select{
    border: 1px solid var(--ion-color-medium);
    border-radius: 2px;
    --padding-end: 5px;
}
ion-input{
    border: 1px solid var(--ion-color-medium);
    border-radius: 2px;
}
.ion-page{
    align-items: center!important;
}

.field{
    position: relative;
    margin-bottom: 25px;
    ion-label{
        font-size: 16px;
        margin-bottom: 10px;
        display: block;
    }
    &.range{
        ion-label{
            margin-bottom: 0;
        }
        ion-range{
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    p.infos{
        padding: 2px 0 0;
        margin: 0;
        font-size: 14px;
    }
    &.button{
        padding-top: 25px;
        display: flex;
        justify-content: center;
    }
    span.icon-input{
        position: absolute;
        right: 0;
        display: block;
        height: 41px;
        width: 41px;
        text-align: center;
        margin-top: -42px;
        line-height: 41px;
    }
    ion-input.icon-input{
        --padding-end: 43px;
    }
    &.readOnly{
        opacity: 0.4;
    }
    &.toggle{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.flexZone{
    min-height: 100%;
    display: flex;
    flex-flow: column;
}
.seeFaq{
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-content: center;
    div{
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-content: center;
        p{
            text-align: center;
        }
        ion-icon{
            margin: 0 auto;
            width: 30px;
            height: 30px;
            padding: 10px;
            background: var(--ion-color-light);
        }
    }
}

ion-slides{
    height: 100%;
    ion-slide{
        flex-direction: column;
        justify-content: space-around;
        ngx-chartjs{
            display: block;
            height: 80%;
            width: 100%;
            div{
                height: 100%!important;
                width: 100%!important;
                canvas{
                    display: block;
                    height: 100%!important;
                    width: 100%!important;
                }
            }
        }
    }
}
.dashboard{
    .widget{
        .content{
            flex-direction: column;
            justify-content: space-around;
            h1{
                margin-bottom: 25px!important;
            }
            ngx-chartjs{
                display: block;
                height: 80%;
                width: 100%;
                div{
                    height: 100%!important;
                    width: 100%!important;
                    canvas{
                        display: block;
                        height: 100%!important;
                        width: 100%!important;
                    }
                }
            }
        }
    }
}

ion-footer{
    .chooseTemps{
        position: relative;
        padding: 0;
        margin: 0;
        background:  var(--ion-color-light);
        h6{
            position: relative;
            display: block;
            margin: 0;
            text-align: center;
            padding: 20px 0 0;
            font-size: 14px;
        }
        ion-label{
            font-size: 13px;
            font-weight: bold;
        }
    }
}

ion-menu{
    ion-content{
        --background:  var(--ion-color-light);
        --padding-top: 0;
        --padding-end: 0;
        --padding-bottom: 0;
        --padding-start: 0;
        .header{
            padding: 25px;
            background:  var(--ion-color-primary-shade);
            h4{
                color:  var(--ion-color-medium-contrast);
                padding: 0;
                margin: 0;
                font-size: 16px;
                font-weight: bold;
            }
        }
        ul.list{
            padding: 0;
            margin: 0;
            list-style: none;
            li{
                padding: 10px;
                background: var(--ion-color-medium-contrast);
                border-bottom: 1px solid var(--ion-color-light-shade);
                display: flex;
                justify-content: space-between;
                .begin{
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    flex-direction: column;
                    font-size: 18px;
                    width: 18px;
                }
                .content{
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    flex-direction: column;
                    width: calc(100% - 18px);
                    padding: 0 0 0 20px;
                    box-sizing: border-box;
                    .subMenu{
                        font-size: 14px;
                        ion-icon{
                            font-size: 14px;
                            color: var(--ion-color-primary-shade);
                            margin: 0 3px;
                            &.danger{
                                color: var(--ion-color-danger);
                            }
                            &.success{
                                color: var(--ion-color-success);
                            }
                            
                        }
                        border-bottom: 1px solid var(--ion-color-light-shade);
                        margin-bottom: 5px;
                    }
                    ion-label{
                        font-size: 16--ion-color-medium-tintpx;
                        box-sizing: border-box;
                        padding: 0 5px;
                    }
                    .label{
                        padding: 0 5px;
                    }
                }
            }
        }
    }
}

div.faqs{
    max-width: 420px;
    width: 85%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    p, h1, h2, h3, h4, h5, h6{
        margin: 0;
        font-size: 14px;
        padding: 2px 0;
        font-weight: normal;
    }
    h3{
        font-size: 16px;
        padding-top: 15px;
    }
    img{
        max-width: 420px;
        width: 85%;
    }
}

.alert-radio-label, .item.sc-ion-label-md-h, .item .sc-ion-label-md-h, .item.sc-ion-label-ios-h, .item .sc-ion-label-ios-h{
    white-space: pre-wrap!important;
}

ion-header ion-toolbar ion-title .toolbar-title{
    white-space: pre-wrap!important;
    width: calc(100% - 140px);
}

.alert-tappable{
    contain: none!important;
    height: auto!important;
}

app-legal-notice, app-credits, app-faqs, app-new-simulations {
    background: var(--ion-background-color);
}

app-simulations-visualization{
    h1{
        font-size: 18px!important;
    }
}
